export const initialize = (selectorString, component) => {
  const behaviours = [
    ...document.querySelectorAll(`[data-behaviour=${selectorString}]`),
  ]

  if (!behaviours) return

  return behaviours.forEach((el) => new component(el))
}

export const initializeWithClass = (selectorString, component) => {
  const instances = [...document.querySelectorAll(selectorString)]

  instances.forEach((el) => new component(el))
}
