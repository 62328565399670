import throttle from 'lodash.throttle'
import { trapFocus } from '../../../helpers/trapFocus'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

class HeaderSearch {
  constructor(el, { ...args }) {
    this.el = el
    this.onSearchOpenCallback = args.onSearchOpenCallback
    this.onSearchCloseCallback = args.onSearchCloseCallback
    this.searchButton = el.querySelector('[data-search-button]')
    this.searchContent = el.querySelector('[data-search-content]')
    this.closeBtn = el.querySelector('[data-close]')
    this.hasSearch = false
    this.isSearchOpen = false

    /* Events */
    this.onSearchClick = this.onSearchClick.bind(this)
    this.trapFocus = this.trapFocusInSearch.bind(this)
    this.throttledResize = throttle(this.setSearchContentStyles.bind(this))
    this.onSearchCloseClick = this.handleSearchCloseClick.bind(this)

    window.addEventListener('openSearchEvent', this.openSearch.bind(this))
  }

  get headerHeight() {
    return this.el.getBoundingClientRect().bottom
  }

  getSearchContentLinks() {
    return [
      ...this.searchContent.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), input'
      ),
    ].filter((el) => {
      const hiddenWithTabindex = el.getAttribute('tabindex') == '-1'
      return (
        getComputedStyle(el).getPropertyValue('display') !== 'none' &&
        !hiddenWithTabindex
      )
    })
  }

  trapFocusInSearch(e) {
    const escIsPressed = e.keyCode === 27 || e.key === 'Esc'
    const searchContentLinks = this.getSearchContentLinks()

    trapFocus(e, null, searchContentLinks)

    if (escIsPressed) {
      this.closeSearch()
      this.searchButton.focus()
    }
  }

  preventFocusOnLinks(preventFocus) {
    const linksWithinSearchContent = [
      ...this.searchContent.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), input'
      ),
    ]

    linksWithinSearchContent.forEach((el) => {
      if (preventFocus === true) {
        return el.setAttribute('tabindex', '-1')
      } else {
        return el.removeAttribute('tabindex')
      }
    })
  }

  openSearch() {
    this.searchContent.hidden = false
    this.preventFocusOnLinks(false)

    setTimeout(() => {
      document.body.classList.add('is-search-open')
      disableBodyScroll(this.searchContent)
      this.searchButton.querySelector('[data-text]').innerText = 'Close'
      this.setSearchContentStyles()
      this.el.addEventListener('keydown', this.trapFocus)
      this.isSearchOpen = true

      // Focus on the search input field
      const searchInput = this.searchContent.querySelector('#menu_search')
      if (searchInput) {
        searchInput.focus()
      }
    }, 100)
  }

  closeSearch() {
    document.body.classList.remove('is-search-open')
    this.preventFocusOnLinks(true)
    enableBodyScroll(this.searchContent)
    this.searchButton.querySelector('[data-text]').innerText = 'Search'
    this.el.removeEventListener('keydown', this.trapFocus)
    this.isSearchOpen = false

    setTimeout(() => {
      this.searchContent.hidden = true
    }, 300)
  }

  setSearchContentStyles() {
    this.searchContent.style.top = 0
    this.searchContent.style.height = `${window.innerHeight}px`
  }

  onSearchClick() {
    if (!this.isSearchOpen) {
      this.openSearch()

      if (
        this.onSearchOpenCallback &&
        typeof this.onSearchOpenCallback === 'function'
      ) {
        this.onSearchOpenCallback()
      }
    } else {
      this.closeSearch()
      if (
        this.onSearchCloseCallback &&
        typeof this.onSearchCloseCallback === 'function'
      ) {
        this.onSearchCloseCallback()
      }
    }
  }

  handleSearchCloseClick() {
    this.onSearchClick()
    this.searchButton.focus()
  }

  initSearch() {
    document.body.classList.add('has-search')
    this.preventFocusOnLinks(true)
    this.searchContent.hidden = true
    this.searchButton.addEventListener('click', this.onSearchClick)
    this.closeBtn.addEventListener('click', this.onSearchCloseClick)
    window.addEventListener('resize', this.throttledResize)
    this.hasSearch = true
  }

  destroySearch() {
    document.body.classList.remove('has-search')
    this.preventFocusOnLinks(false)
    enableBodyScroll(this.searchContent)
    this.searchContent.removeAttribute('style')
    this.searchButton.removeEventListener('click', this.onSearchClick)
    window.removeEventListener('resize', this.throttledResize)
    this.hasSearch = false
  }
}

export default HeaderSearch
