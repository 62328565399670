class SearchSidebar {
  constructor(el) {
    this.el = el
    this.onLoad()
  }

  onLoad() {
    this.el.addEventListener('change', () => {
      this.el.submit()
    })
  }
}

export default SearchSidebar
