import throttle from 'lodash.throttle'
import clamp from '../helpers/clamp'

class ScrollProgress {
  constructor(el) {
    this.el = el
    this.progressBar = this.el.querySelector('.c-scroll-progress__bar')

    // console.log(this.el, this.progressBar)

    this.throttledScroll = throttle(this.onScroll.bind(this), 10)
    window.addEventListener('scroll', this.throttledScroll)
  }

  onScroll() {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
    const scrolled = clamp(0, 100, (winScroll / height) * 100)
    this.progressBar.style.width = `${scrolled}%`
  }
}

export default ScrollProgress
