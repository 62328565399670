import throttle from 'lodash.throttle'

class Parallax {
  constructor(el) {
    this.el = el
    this.noMotionPreference = window.matchMedia(
      '(prefers-reduced-motion: no-preference)'
    )
    this.imageWrapper = this.el.querySelector('[data-img-wrapper]')
    this.heading = this.el.querySelector('[data-content]')

    this.handleScroll = throttle(() => this.onScroll(), 5)

    if (this.noMotionPreference.matches) {
      this.init()
    }
  }

  init() {
    document.body.classList.add('has-parallax')
    window.addEventListener('scroll', this.handleScroll)
    this.imageWrapper.style.bottom = 0
  }

  shouldHideHeaderContent() {
    const headingBottom = document.querySelector('[data-header]').clientHeight

    return this.heading.getBoundingClientRect().top < headingBottom - 50
  }

  showHideHeaderContent() {
    if (this.shouldHideHeaderContent()) {
      this.heading.style.opacity = 0
    } else {
      this.heading.style.opacity = 1
    }
  }

  doParallax() {
    const { bottom } = this.el.getBoundingClientRect()

    if (bottom > 0) {
      const y = window.scrollY * 0.2
      this.imageWrapper.style.transform = `translate3d(0, ${y}px, 0)`
    }
  }

  onScroll(e) {
    this.doParallax()
    this.showHideHeaderContent()
  }

  destroy() {
    document.body.classList.remove('has-parallax')
    window.removeEventListener('scroll', this.handleScroll)
    this.imageWrapper.removeAttribute('style')
  }
}

export const render = (el) => new Parallax(el)
export default Parallax
