import { minDesktop } from '../helpers/resize'
import Parallax from './parallax'

class HeroVideo {
  constructor(el) {
    this.el = el
    this.video = el.querySelector('video')
    this.noMotionPreference = window.matchMedia(
      '(prefers-reduced-motion: no-preference)'
    )

    if (!this.video) return

    /* Init video */
    this.button = el.querySelector('[data-video-btn]')
    this.el.classList.add('is-video-loading')

    this.playButton = el.querySelector('[data-play-button]')
    this.pauseButton = el.querySelector('[data-pause-button]')
    this.muteButton = el.querySelector('[data-mute-button]')
    this.unmuteButton = el.querySelector('[data-unmute-button]')

    if (this.playButton) {
      this.playButton.addEventListener('click', this.play.bind(this))
    }

    if (this.pauseButton) {
      this.pauseButton.addEventListener('click', this.pause.bind(this))
    }

    if (this.muteButton) {
      this.muteButton.addEventListener('click', this.mute.bind(this))
    }

    if (this.unmuteButton) {
      this.unmuteButton.addEventListener('click', this.unmute.bind(this))
    }

    if (this.noMotionPreference.matches) {
      this.play()
    }

    this.video.addEventListener('canplay', () => {
      this.el.classList.remove('is-video-loading')
    })
  }

  pause() {
    if (!this.video) return
    if (this.video.paused) return
    this.el.classList.remove('is-playing')
    this.video.pause()
  }

  play() {
    if (!this.video) return
    if (!this.video.paused) return
    this.el.classList.add('is-playing')
    this.video.play()
  }

  mute() {
    if (!this.video) return
    this.el.classList.remove('is-unmuted')
    this.video.muted = true
  }

  unmute() {
    if (!this.video) return
    this.el.classList.add('is-unmuted')
    this.video.muted = false
  }
}

export const render = (el) => new HeroVideo(el)
export default HeroVideo
