class FilterTags {
  constructor(el) {
    this.filterTags = el

    // Scroll related elements
    this.filterScroll = this.filterTags.querySelector(
      '[data-filter-tags-scroll]'
    )
    this.filterWrap = this.filterTags.querySelector('[data-filter-tags-wrap]')

    this.categoryLinks = [...document.querySelectorAll('[data-category-link]')]

    window.addEventListener('resize', this.onResize.bind(this))
    this.bindCategoryLinks()

    // Trigger auto-scroll based on the sessionStorage flag
    this.initScrollBehavior()
    this.onResize()
  }

  onResize() {
    if (this.filterWrap.scrollWidth > this.filterWrap.clientWidth) {
      this.filterWrap.classList.add('c-filter-tags__wrap--scrollable')
      this.filterScroll.classList.add('flex')
      this.filterScroll.classList.remove('hidden')
    } else {
      this.filterWrap.classList.remove('c-filter-tags__wrap--scrollable')
      this.filterScroll.classList.remove('flex')
      this.filterScroll.classList.add('hidden')
    }
  }

  scrollToAnchor() {
    const element = this.filterTags
    if (element) {
      const offsetPosition = element.offsetTop - 200
      window.scrollTo({
        top: offsetPosition,
        left: 0,
        behavior: 'instant',
      })
    }
  }

  bindCategoryLinks() {
    this.categoryLinks.forEach((link) => {
      link.addEventListener('click', () => {
        console.log('Clicked')
        sessionStorage.setItem('autoScrollToAnchor', 'true')
      })
    })
  }

  initScrollBehavior() {
    if (sessionStorage.getItem('autoScrollToAnchor') === 'true') {
      console.log('session found')
      sessionStorage.removeItem('autoScrollToAnchor') // Clear the flag
      window.addEventListener('DOMContentLoaded', () => this.scrollToAnchor())
    }
  }
}

export default FilterTags
