import { minTablet } from '../helpers/resize'

const setPadding = () => {
  const main = document.querySelector('#main')
  const header = document.querySelector('[data-header]')

  if (minTablet.matches) {
    main.style.paddingTop = `${header.offsetHeight}px`
  } else {
    main.style.paddingTop = `0`
  }
}

const onLoad = () => {
  setPadding() // call once on load

  // Attach to resize event
  window.addEventListener('resize', setPadding)
}

export default onLoad
