class Card {
  constructor(el) {
    this.card = el
    this.mainLink = this.card.querySelector('[data-link]')
    this.clickableElements = [...this.card.querySelectorAll('[data-clickable]')]

    this.card.addEventListener('click', this.handleClick.bind(this))
    this.clickableElements.forEach((el) => {
      el.addEventListener('click', (e) => e.stopPropagation())
    })
  }

  handleClick(e) {
    this.mainLink.click()
  }
}

export default Card
