import { initialize, initializeWithClass } from './helpers/init'
import { flare } from '@flareapp/flare-client'

// Only enable Flare in production, we don't want to waste your quota while you're developing:
if (process.env.NODE_ENV === 'production') {
  flare.light()
}

import loadBundles from './helpers/loadBundles'

/* Optional */
import svg4everybody from 'svg4everybody'
import fitvids from 'fitvids'
import 'focus-visible'
import dragscroll from 'dragscroll'

/* Import components as needed */
// import CheckFollowingBlocks from './components/checkFollowingBlocks'
import MenuDropdown from './components/menu/menuDropdown'
import MenuSimple from './components/menu/menuSimple'
import Filter from './components/filter'
import InstagramShare from './components/instagramShare'
import SearchSidebar from './components/searchSidebar'
import ShareButtonsMore from './components/shareButtonsMore'
import Card from './components/card'
import onLoad from './components/onLoad'
import Pagination from './components/pagination'
import Breadcrumbs from './components/breadcrumbs'
import FilterTags from './components/filterTags'
import Table from './components/table'
import ScrollProgress from './components/scrollProgress'
import Counter from './components/counter'
import CaseStudy from './components/casestudy'
import HeroVideo from './components/heroVideo'
import StickyNavigation from './components/stickyNavigation'

document.body.classList.remove('no-js') // Path might differ based on your folder structure

// document.body.classList.add('low-energy')

svg4everybody({
  polyfill: true,
})

fitvids()

/* Initialize components as needed */
initialize('menu', MenuDropdown)
initialize('menu-simple', MenuSimple)
initialize('card', Card)
// initializeWithClass('.entry-content', CheckFollowingBlocks)
initialize('filter', Filter)
initialize('instagram-share', InstagramShare)
initialize('share-buttons', ShareButtonsMore)
initialize('search-sidebar', SearchSidebar)
initialize('breadcrumbs', Breadcrumbs)
initialize('filter-tags', FilterTags)
initialize('scroll-progress', ScrollProgress)
initializeWithClass('.wp-block-table', Table)
initialize('counter', Counter)
initialize('casestudy', CaseStudy)
initialize('pagination', Pagination)
initialize('sticky-navigation', StickyNavigation)

onLoad()

/*
  ** Conditionally load bundles **
  ---
  Only do this if you have large components or dependencies that you don't want to load on every page (e.g. a Vue application). Otherwise import and initialise them in the normal way, above.
*/
const bundles = [
  {
    selector: 'accordion',
    fileName: 'accordion',
  },
  {
    selector: 'tabs',
    fileName: 'tabs',
  },
  {
    selector: 'accordion-tabs',
    fileName: 'accordionTabs',
  },
  {
    selector: 'parallax',
    fileName: 'parallax',
  },
  {
    selector: 'hero-video',
    fileName: 'heroVideo',
  },
  {
    selector: 'hero-video-modal',
    fileName: 'heroVideoModal',
  },
  {
    selector: 'hero-carousel',
    fileName: 'heroCarousel',
  },
  {
    selector: 'gallery',
    fileName: 'customGallery',
  },
]
loadBundles(bundles)

/* Only load Vue if #app element present on the page */
const importVue = () => {
  if (!document.querySelector('#app')) return

  import('./vue/app.js').then((object) => {
    const vueApp = object.default
    vueApp()
  })
}

importVue()
