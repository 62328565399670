class ShareButtonsMore {
  constructor(el) {
    this.el = el
    this.moreButton = el.querySelector('[data-share-more-btn]')

    this.moreButton.addEventListener('click', this.onClick.bind(this))
  }

  onClick(e) {
    this.el.classList.add('c-share-buttons--show-more')
  }
}

export default ShareButtonsMore
