class StickyNavigation {
  constructor() {
    this.stickyNav = document.querySelector('.sticky-navigation')
    this.footer = document.querySelector('footer')
    this.lastScrollY = 0
    this.throttleTimeout = null

    if (this.stickyNav) {
      window.addEventListener('scroll', () => this.onScroll())
    }
  }

  onScroll() {
    if (this.throttleTimeout === null) {
      this.throttleTimeout = setTimeout(() => {
        this.handleScroll()
        this.throttleTimeout = null
      }, 100) // Adjust the throttle time as needed
    }
  }

  handleScroll() {
    const currentScroll = window.scrollY
    if (currentScroll === this.lastScrollY) {
      return
    }

    this.lastScrollY = currentScroll
    const footerTop = this.footer.offsetTop
    const stickyNavBottom =
      currentScroll +
      this.stickyNav.getBoundingClientRect().top +
      this.stickyNav.offsetHeight

    if (
      stickyNavBottom > footerTop &&
      currentScroll + window.innerHeight > footerTop
    ) {
      this.stickyNav.classList.add('hide-sticky-nav')
    } else {
      this.stickyNav.classList.remove('hide-sticky-nav')
    }
  }
}

export default StickyNavigation
