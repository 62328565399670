import { CountUp } from 'countup.js/dist/countUp.min.js'

function countDecimals(number) {
  if (Math.floor(number) === number) return 0

  var str = number.toString()
  if (str.indexOf('.') !== -1 && str.indexOf('-') !== -1) {
    return str.split('-')[1] || 0
  } else if (str.indexOf('.') !== -1) {
    return str.split('.')[1].length || 0
  }
  return str.split('-')[1] || 0
}

class Counter {
  constructor(el) {
    this.el = el
    this.counters = this.el.querySelectorAll('.countup')
    this.statCountUp()
  }

  statCountUp() {
    if (typeof this.counters !== 'undefined') {
      this.counters.forEach((counter) => {
        let countTo = counter.getAttribute('data-count-to')

        const prefersReducedMotion = window.matchMedia(
          '(prefers-reduced-motion: reduce)'
        ).matches

        const animationDuration = prefersReducedMotion ? 0 : 3 // Set duration to 0 if reduced motion is preferred

        counter = new CountUp(counter, countTo, {
          duration: animationDuration,
          enableScrollSpy: true,
          decimalPlaces: countDecimals(countTo),
        })
        counter.start()
      })
    }
  }
}

export default Counter
